@import "src/styles/variables";

.side-menu.ui.text.menu {
  &.vertical {
    border-left: 1px solid $default-border-color;
    height: fit-content;
    .item {
      padding-left: 10px;
      border-radius: 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        border-left: 2px solid;
      }
    }
  }
  &:not(.vertical) {
    width: 100%;
    border-bottom: 1px solid $default-border-color;
    margin-left: 0;

    .item {
      align-self: flex-end;

      &.active {
        border-bottom: 2px solid;
      }
    }
  }

  .item {
    &.active {
      border-color: $default-theme-color;
      font-weight: bold;
      color: $default-theme-color;
    }
  }

  &.major {
    .item {
      font-weight: bold;
    }
  }
}
