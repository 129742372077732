.react-multi-email {
  > span {
    color: #767676 !important;
    font-size: 0.85em;
    background: #ffffff;
    display: inline-block !important;
    top: -50% !important;
    transform: translate(0, 50%) !important;
  }

  &.empty {
    > span {
      top: 50% !important;
      transform: translate(0, -50%) !important;
      font-size: inherit !important;
    }

    &.focused {
      > span {
        display: none !important;
      }
    }
  }
}
