.page-header .ui.menu .item:before {
    content: none !important;
}

.account-settings-dropdown.ui.button.icon.labeled {
    background-color: unset;
    padding-right: 4.07142857em !important;

    > .icon {
        background-color: unset;
        right: 0;
        left: unset;
    }
}

.organization-select-menu.ui.floating.dropdown {

    > .menu {
        top: 0;
        margin-top: 0 !important;
    }
}
