@import "src/styles/variables.scss";

.top-menu.ui.menu {

  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif !important;
  width: 200px;
  box-shadow: unset;
  border-top: none;
  border-left: none;
  border-right: none;
  display: flex;
  justify-items: center;
  padding: 0.5em;
  top: 3.4em;
  z-index: 1;

  .top-menu-item.MuiChip-root.MuiChip-clickable {

    font-weight: 500;
    font-size: 0.9rem;

    &:not(.active) {
      border: none;
    }
    &.active {
      background-color: $default-theme-color;
      color: white;
    }
  }
}
