.MuiTableContainer-root {
  thead {
    .MuiTableCell-root {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 700;
      font-size: 1em;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
    }

    .sort-icon-inactive svg {
      opacity: 0.3;
    }
  }

  tbody {
    .MuiTableRow-root {

      &.clickable:hover {
        cursor: pointer;
      }

      .MuiTableCell-root {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 1em;
        padding-top: 7px !important;
        padding-bottom: 7px !important;

        &.clickable:hover, .clickable:hover {
          cursor: pointer;
          text-decoration: underline;
          text-decoration-color: #1976d2;
        }
      }
    }
  }

  &.compact-table {
    thead {
      .MuiTableCell-root {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
      }
    }
    tbody {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding-top: 2px !important;
          padding-bottom: 2px !important;
        }
      }
    }
  }
}
