.certificate-file {
  canvas {
    width: 800px !important;
    height: auto !important;
  }
  .react-pdf__message--loading {
    text-align: center;
    margin-top: 3em;
  }
}
