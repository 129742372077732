.status-chip {
  border-radius: 5px !important;
  font-weight: 500;

  .MuiChip-label {
    //width: 105px;
    text-align: center;
    text-transform: capitalize;
  }

  &.approved, &.compliant, &.active {
    background-color: rgba(0, 128, 0, 0.1);
    &.MuiChip-clickable:hover {
      background-color: rgba(0, 128, 0, 0.2);
    }
    span, svg {
      color: green;
    }
  }

  &.pending, &.not-compliant, &.inactive {
    background-color: rgba(225, 165, 0, 0.1);
    &.MuiChip-clickable:hover {
      background-color: rgba(225, 165, 0, 0.2);
    }
    span, svg {
      color: chocolate;
    }
  }

  &.review, &.manual-review,{
    background-color: rgba(128, 0, 128, 0.1);
    &.MuiChip-clickable:hover {
      background-color: rgba(128, 0, 128, 0.2);
    }
    span, svg {
      color: purple;
    }
  }

  &.expired {
    background-color: rgba(139, 0, 0, 0.1);
    &.MuiChip-clickable:hover {
      background-color: rgba(139, 0, 0, 0.2);
    }
    span, svg {
      color: darkred;
    }
  }
}
