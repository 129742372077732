@import "variables.scss";

p {
  margin: 0 !important;
}

.ui.header, .ui.button {
  font-family: $font-family;
  font-weight: 600;
}

.ui.menu {
  font-family: $font-family;
}

.ui.secondary.pointing.menu {
  .active.item, .active.item:hover {
    border-color: $default-theme-color;
  }
}
