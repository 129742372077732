@import "~semantic-ui-css/semantic.css";
@import "~@aws-amplify/ui-react/styles.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "semantic-override.scss";
@import "react-table";

body {
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif !important;
  // todo: demo only, need to figure out theme color and palette
  .login-container {
    height: 100vh;
    max-width: 100%;
    background: #f3f4f7;

    .amplify-tabs-item[data-state=active] {
      color: rgba(200,0,0,1);
      border-color: rgba(200,0,0,1);
    }

    .amplify-tabs-item[data-state=inactive] {
      color: #767676;
    }

    .amplify-button[data-variation=primary], .amplify-button[data-variation=primary][data-loading=true]:hover {
      background-color: rgba(200,0,0,0.7);
    }

    .amplify-button:hover {
      border-color: rgba(200,0,0,0.3);
      background-color: rgba(200,0,0,0.1);
    }

    .amplify-button[data-variation=primary]:hover {
      border-color: rgba(200,0,0,1);
      background-color: rgba(200,0,0,1);
    }

    .amplify-button[data-variation=link] {
      color: rgba(200,0,0,1);
    }
  }
}
