@import "/src/styles/variables.scss";

.status-filter {
  .item {
    border-bottom: 2px solid transparent !important;
    margin-right: 1.5em !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &.active {
      border-color: $default-theme-color !important;

      .ui.label {
        background-color: $default-theme-color;
      }
    }

    .ui.label {
      padding: 0.3em !important;
    }
  }
}

.vendor-view {
  .ui.overlay.right.thin.visible.sidebar {
    width: 100%;
  }
}

.vendors-table {
  tbody {
    .MuiTableRow-root {
      .MuiTableCell-root {
        .ui.dropdown .menu.right {
          left: unset;
          right: 0;
        }
      }
    }
  }
}
