@import "src/styles/variables";

.certificate-details-wrapper {
  .MuiDialog-container {
    .MuiPaper-root.MuiDialog-paper {
      background: #f3f4f7;
    }

    .ui.feed > .event > .content .summary {
      font-weight: 500;
    }
  }

  .ui.tiny.images {

    margin-top: 1em;

    .ui.image {
      padding: 0.5em;
      margin: 0;
      &:hover {
        cursor: pointer;
      }
      &.image-selected {
        background-color: darkgrey;
      }
    }
  }

  .react-datepicker-wrapper {
    width: auto;
  }

  .certificate-file {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
    .react-pdf__message--loading {
      text-align: center;
      margin-top: 3em;
    }
  }
}

.add-insurance-type-button {
  color: $default-theme-color !important;
  :hover {
    background-color: rgba(219, 40, 40, 0.04);
  }
}
.other-info-section {
  .ui.feed > .event > .content .meta a.delete-btn {
    &:hover {
      text-decoration: underline;
    }
  }
}


.display-error-line-1 {
  .MuiInputBase-inputMultiline{
    &::first-line {
    color: #d32f2f !important;
    }
  }
}

.display-error-line-234 {
  .MuiInputBase-inputMultiline{
    color: #d32f2f;
    &::first-line{
      color: initial;
    }
  }
}

.coverages-validation-error {
  color: #d32f2f;
  input {
    color: #d32f2f;
  }
}